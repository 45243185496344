// bg-position(x,y) width height
$icons: (
	'barras': 0px -42px 25px 15px,
	'caminhao': -35px -42px 18px 13px,
	'caminho': -26px -67px 18px 13px,
	'carrinhalt': -106px 0px 15px 15px,
	'carrinho': -78px 0px 18px 18px,
	'html': 0px 0px 32px 32px,
	'lixo': -106px -50px 9px 12px,
	'lupa': -78px -28px 18px 18px,
	'play': -42px 0px 26px 30px,
	'playalt': -106px -25px 14px 15px,
	'usuario': 0px -67px 16px 18px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 121px 85px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 121px 85px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
