.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.main__logo{
	@include media-breakpoint-only (lg) {
		max-width: 140px;
		margin: 0px 25px;
	}
}
.topo__pesquisa{
	.pesquisa__drop{
		display: none !important;
	}
}

.topo{
	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
	}
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.categ__destaque{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	width: 100%;

	@include media-breakpoint-up (lg) {
		display:flex;
		justify-content: space-between;
		padding-left: 15px !important;
	}

	.categ__destaque__item{
		display: flex;
	}

	.categ__destaque__link{
		display: flex;
		align-items: center;
		column-gap: 5px;
	}
}
.segura__menu{
	.menu__topo__especial{
		width: 100%;
		position: relative;

		.menu__toggler__diferente{
			background-color: #e78933;
			color: #fff;
			display: flex;
			position: relative;
			border: none;
			min-height: 60px;
			padding-right: 10px;
			justify-content: flex-start;
			font-family: poppinsmedium;
			font-size: 14px;
			padding-left: 10px;
			letter-spacing: -0.5px;
			line-height: 16px;
			text-align: left;
			align-items: center;
			width: 100%;
			column-gap: 8px;

			&[aria-expanded="true"]{
				background-color: #2d286b;
				color: #fff;
			}

			.bars{
				margin-left: 10px;
			}
		}
	}
}
.segura__menu__collapse{
	background-color: #2d286b;

	a{
		transition: all 0.3s linear;
		&:hover{
			text-decoration: none;

		}
	}

	ul{
		@include media-breakpoint-up (lg) {
			overflow-y: auto;
			max-height: calc(100vh - 217px);

			&::-webkit-scrollbar{
				appearance: none;
				width: 15px;
			}
			&::-webkit-scrollbar-thumb{
				background-color: #fff;
				border: 5px solid #2d286b;
				border-radius: 10px;
			}
		}
	}

	li{
		border-bottom: 1px solid #fff;
		transition: all 0.3s linear;
		&:last-child{
			border-bottom: none;
		}
		.menu__link{
			transition: all 0.3s linear;
		}
		&:hover{
			background-color: #e78933;
			.menu__link{
				background-color: #e78933;
				color: #fff;
			}
		}
		.categoria__controls{
			padding-right: 25px;
			position: relative;

			button{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 15px;
				background-color: #fff;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px;
				border: none;

				@include media-breakpoint-down (md) {
					right: 5px;
				}
			}
		}
	}
	.dropcateg{
		color: #fff;
		background-color: #2d286b;

		a{
			background-color: #e78933;
			display: block;
			color: #fff;
			padding: 10px 15px;
			transition: all 0.3s linear;
			border-bottom: 1px solid #fff;

			&:hover{
				background-color: #e78933 !important;
				color: #fff !important;
				text-decoration: none;
			}
		}

		li{
			border-bottom: none;
			&:last-child{
				a{
					border-bottom: unset;
				}
			}
		}
	}

	@include media-breakpoint-up (lg) {
		position: absolute;
		left: 0px;
		top: 100%;
		width: 100%;
		min-width: 250px;
	}

	a{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 15px;
		background-color: #2d286b;
		font-family: poppinssemibold;
		font-size: 13px;
		line-height: 15px;
	}
}
.main__menu{
	font-family: poppinsregular;
	font-size: 14px;
	line-height: 18px;

	a{
		transition: all 0.3s linear;
		&:hover{
			text-decoration: none;
		}
	}
}
.segura__login__topo{
	position: relative;

	@include media-breakpoint-down (md) {
		margin-top: 0px;
	}


    .login__toggler,
	.logado__toggler{
        display: flex;

    }
    .dropdown{
        display: none !important;

		&:hover{
            text-decoration: none;
			.login__icone{
				&:before{
					opacity: 1;
				}
			}
        }
    }

    &.logado{
        .login__toggler{
            display: none !important;
        }
        .dropdown{
            display: block !important;
        }
    }
}
.logado__nome{
    padding: 24px 20px 22px 20px;
	background: #2d286b;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
	text-align: left;
	border-radius: 5px 5px 0px 0px;
	font-family: poppinssemibold;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 10px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 16px 10px 16px 10px;
        border-bottom: 1px solid rgba(#000000, 0.1);
        font-size: 14px;
        line-height: 14px;
        font-family: 'poppinslight';
        color: #000000;

        &:hover{
            color: #e78933;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #fff;
    align-items: center;
	border-radius: 5px;
	padding: 0px;
	border: none;

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2d286b;
        min-width: 50px;
        width: 50px;
        height: 50px;
        font-size: 18px;
        line-height: 18px;
        border: none;
		border-radius: 5px;
		position: relative;
		color: #fff;
		transition: all 0.3s linear;

		&:hover{
			background-color: #2d286b;
			color: #fff;
		}
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'poppinsregular';
        font-size: 13px;
        line-height: 16px;
        padding: 17px 20px 17px 20px;
        border: none;
        background-color: transparent;
        color: #000000;
        border: none;
        border-radius: 8px;

        &::placeholder{
            color: #000000;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}

.encerrar__item{
    padding: 18px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: transparent;
		border: none;
		color: #fff;
        font-family:'poppinssemibold';
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        transition: all 0.3s linear;
		position: relative;
		border-radius: 5px;
		background-color: #2d286b;


		span{
			position: relative;
			z-index: 200;
		}

		@include media-breakpoint-down(md) {
            height: 28px;
			border-radius: 12px;
        }

        &:hover{
            color: #fff;
            text-decoration: none;
			background-color: #e78933;
        }
    }
}
@include media-breakpoint-up (lg) {
	.carrinho__link__topo,
	.logado__toggler,
	.login__toggler{
		display: flex;
		align-items: center;
		padding: 10px 20px;
		border-radius: 8px;
		border: none;
		background-color: #e4e4e4;
		align-items: center;
		color: #000;
		column-gap: 10px;
		font-family: poppinsmedium;
		text-align: left;
		min-height: 50px;
		transition: all 0.3s linear;

		.icon__mask{
			color: #e78933;
			transition: all 0.3s linear;
		}

		@include media-breakpoint-only (lg) {
			padding: 10px 10px;
		}

		.carrinho__texto,
		.login__texto{
			font-size: 11px;
			line-height: 13px;

			span{
				font-size: 14px;
				line-height: 16px;
			}
		}

		&:hover{
			background-color: #e78933;
			color: #fff;
			text-decoration: none;

			.icon__mask{
				color: #fff;
			}
		}
	}
	.main__menu{
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-grow: 1;
		max-width: 390px;
		column-gap: 15px;

		.menu__item{
			display: flex;
		}

		.menu__link{
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #000;

			&:hover{
				color: #e78933;
				text-decoration: none;
			}
		}
	}
	.topo__main{
		.main__logo{
			padding: 10px 0px;
		}
		.topo__cima{
			background-color: #fff;
			.container{
				display: flex;
				justify-content:space-between;
				position: relative;
				padding-left: 0px;

				.cima__right{
					display: flex;
					justify-content: space-between;
					column-gap: 20px;
					align-items: center;
				}
			}
		}
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: 0px !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000000, 0.6);
		transform: unset !important;
		top: calc(100% + 15px) !important;
		border-radius: 5px;
	}
	.logado__toggler{
		&[aria-expanded="true"] {
			&:after{
				display: block;
			}
		}

		&:after{
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 10px solid #2d286b;
			content: '';
			position: absolute;
			left: 19px;
			bottom: -15px;
			display: none;
			z-index: 100;
		}
	}

	.topo__mobile,
	.mobile__icone{
		display: none;
	}
	.segura__contato{
		display: flex;
		align-items: center;
		font-family: poppinslight;
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 5px;

		.icon{
			margin-right: 2px;
		}

		@include media-breakpoint-down (lg) {
			flex-direction: column;
			row-gap: 5px;
			align-items: flex-start;

			.barrinha{
				display: none;
			}
		}

		.icon__mask{
			color: #e78933;
			margin-right: 2px;
		}
	}
	.topo__baixo{
		background-color: #e78933;
		color: #fff;
		padding-bottom: 20px;

		.segura__pesquisa{
			max-width: 560px;
			padding-top: 10px;
		}
		.container{
			.segura__menu{
				width: 100%;
				display: flex;
				justify-content: space-between;

				.menu__topo__especial{
					width: 100%;
					max-width: 140px;
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.menu__topo__especial{
		background-color: #2d286b;
		.segura__menu__collapse{
			background-color: #2d286b;
			color: #000;

			.menu__link{
				background-color: #2d286b;
				padding: 10px;
				color: #fff;


				span{
					&:nth-child(2) {
						display: none;
					}
				}
			}
		}
	}
	.main__menu{
		a{
			display: block;
			padding: 10px;
			font-family: poppinssemibold;
		}
	}
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		background-color: #fff;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;

		.main__logo{
			padding: 10px;
		}

		.container{
			display: flex;
			flex-direction: column;
		}

		.topo__baixo{
			order: 2;
			background-color: #e78933;
			color: #fff;

			.categ__destaque__link{
				padding: 5px;
			}
		}

		.topo__cima{
			order: 1;
		}

		.segura__pesquisa{
			display: none;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
		.segura__login__topo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
	}
	.dropcateg{
		background-color: #2d286b;
		li{
			border-bottom: none;
			border-bottom: 1px solid #fff;

			&:last-child{
				border-bottom: none;
			}
		}
		a{
			background-color: #e78933 !important;
			display: block;
			color: #fff !important;
			border-bottom: none;
			padding: 10px 15px;

			&:hover{
				background-color: #000 !important;
				color: #e78933 !important;
				text-decoration: none;
			}
		}
	}
	.mbl__logo{
		max-width: 75px;
		width: 100%;
	}
	.main__logo{
		text-align: center;
		padding: 20px;

	}
	.topo__mobile{
		padding: 10px 0px 10px 0px;
		border-bottom: 2px solid #e78933;
		position: relative;
		&:after{
			width: 100%;
			height: 6px;
			position: absolute;
			left: 0px;
			top: 0px;
			background-image: url(../images/borda.jpg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center center;
			content: '';
		}

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.main__menu{
		.menu__link{
			display: block;
			padding: 10px;
			background-color: #fff;
			color: #e78933;
		}
		li{
			&:last-child{
				a{
					border-bottom: none;
				}
			}
		}
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		color: #e78933;
		font-size: 20px;
		line-height: 20px;
		padding: 0px 8px;
	}
	.segura__login__topo{
		.login__icone{
			display: none;
		}
		.login__texto{
			display: none;
		}
		.mobile__icone{
			border: none;
			background-color: transparent;
			color: #e78933;
			font-size: 20px;
			line-height: 20px;
			padding: 0px 8px;
		}
	}
	.segura__carrinho__topo{
		position: relative;
		.carrinho__texto{
			display: none;
		}
		.carrinho__icone{
			display: none;
		}
		.mobile__icone{
			border: none;
			background-color: transparent;
			color: #e78933;
			font-size: 20px;
			line-height: 20px;
			padding: 0px 8px;
		}
	}
	.mbl__left,
	.mbl__right{
		display: flex;
		align-items: center;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000000, 0.6);
        right: -10px !important;
		left: unset !important;
		top: calc(100% + 10px)!important;
		transform: unset !important;
		border-radius: 5px;
    }
	.logado__toggler{
		background-color: transparent;
		color: #e78933;
		padding: 0px;
		border: none;
		&[aria-expanded="true"] {
			&:after{
				display: block;
			}
		}

		&:after{
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 10px solid #2d286b;
			content: '';
			position: absolute;
			right: 10px !important;
			bottom: -10px;
			display: none;
			z-index: 100;
		}
	}
}
