.segura__alterar__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.alterar__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .form-group{
        margin-bottom: 10px;
    }
}
.form__titulo{
    font-family: 'poppinsbold';
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
}
.dados__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.contato__input,
.cadastro__input{
    width: 100%;
    display: block;
    height: auto;
    padding: 17px 24px 17px 24px;
    background-color: #fff;
	border: 1px solid #d1d1d1;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-family: 'poppinsregular';
    resize: none;
	border-radius: 5px;

	@include media-breakpoint-down (sm) {
		border-radius: 18px;
		padding: 11px 20px 11px 20px;
		font-size: 13px;
		line-height: 15px;
	}

    &::placeholder{
        color: #000;
    }
    &:focus{
        outline: none;
    }
}
.alterar__btn{
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
}
.contato__btn{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.dados__btn{
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
.dados__form{
    max-width: 924px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.erro__desc{
    text-align: justify;
    margin-bottom: 30px;
}
.segura__erro{
    max-width: 930px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.erro__botao{
    display: flex;
    justify-content: center;
}
.segura__genero{
    color: #000000;
}
