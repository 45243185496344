.produto__figure{
	margin-bottom: 10px;
}
.angulos__txt{
	font-family: 'poppinsregular';
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 20px;
	padding-top: 10px;
	color: #000;
}
.row__avaliacao{
	padding-bottom: 40px;
}
.row__produto__interna{
	.v__zoom{
		.carousel-inner{
			margin-bottom: 20px;

			// img{
			// 	border-radius: 20px;
			// }
		}
	}
	.v__zoom__galery.owl-carousel{
		padding-top: 30px;
		a{
			display: block;
			position: relative;
			transition: all 0.3s linear;
			box-shadow: 0px 4px 8px rgba(#000, 0.2);

			img{
				border-radius: 10px;
			}

			&.active{
				&:after{
					opacity: 1;
				}
			}


			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
				left: 0px;
				background-color: transparent;
				border: 2px solid #2d286b;
				opacity: 0;
				transition: all 0.3s linear;
				border-radius: 10px;
				overflow: hidden;
			}
		}
		.owl-nav{
			display: flex;
			justify-content:  center;
			padding-top: 20px;

			button{
				background-color: transparent;
				border: none;
				margin: 0px 5px;
				background: transparent;
				padding: 5px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				color: #000;
				transition: all 0.3s linear;

				&:hover{
					.icon__mask{
						background-color: #000;
					}
				}
			}
		}
	}
}
.row__miniaturas{
	.col__auto{
		margin-bottom: 10px;
		margin-right: 10px;
		margin-left: 10px;

		a{
			display: block;
			position: relative;
            transition: all 0.3s linear;
            box-shadow: 0px 4px 8px rgba(#000, 0.2);


			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
                left: 0px;
                background-color: #000;
				opacity: 0;
                transition: all 0.3s linear;
			}
		}

		.zoomGalleryActive{
			&:after{
				opacity: 0.6;
			}
		}

		&:first-child{
			margin-left: 0px;
		}
	}
}
.produto__nome__inserir{
	margin-bottom: 30px;
}
.produto__estampa__titulo{
	color: #2d286b;
	font-family: poppinssemibold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 10px;
}
.v__zoom{
	img{
		border-radius: 5px;
	}
}
.row__produto__interna{
	margin-bottom: 0px;

	.col-lg-6{
		&:first-child{
			position: relative;
			z-index: 10;
		}
		&:last-child{
			position: relative;
			z-index: 5;
		}
	}
}
.rating__stars{
	color: #cacaca;
	i{
		position: relative;
	}
}
.rating__stars .fa-star:after {
  	position: absolute;
	color: #FFCA08;
	left: 0;
}
.rating__stars[data-rating="0.5"] .fas:nth-child(1):after {
  	content: "";
}

.rating__stars[data-rating="1.5"] .fas:nth-child(2):after {
  	content: "";
}

.rating__stars[data-rating="2.5"] .fas:nth-child(3):after {
  	content: "";
}

.rating__stars[data-rating="3.5"] .fas:nth-child(4):after {
 	content: "";
}

.rating__stars[data-rating="4.5"] .fas:nth-child(5):after {
 	content: "";
}

.rating__stars[data-rating="5.5"] .fas:nth-child(6):after {
 	content: "";
}

.rating__stars[data-rating="1"] .fas:nth-child(1),
.rating__stars[data-rating="1.5"] .fas:nth-child(1) {
  	color: #FFCA08;
}

.rating__stars[data-rating="2"] .fas:nth-child(1),
.rating__stars[data-rating="2"] .fas:nth-child(2),
.rating__stars[data-rating="2.5"] .fas:nth-child(1),
.rating__stars[data-rating="2.5"] .fas:nth-child(2) {
  	color: #FFCA08;
}

.rating__stars[data-rating="3"] .fas:nth-child(1),
.rating__stars[data-rating="3"] .fas:nth-child(2),
.rating__stars[data-rating="3"] .fas:nth-child(3),
.rating__stars[data-rating="3.5"] .fas:nth-child(1),
.rating__stars[data-rating="3.5"] .fas:nth-child(2),
.rating__stars[data-rating="3.5"] .fas:nth-child(3) {
  	color: #FFCA08;
}

.rating__stars[data-rating="4"] .fas:nth-child(1),
.rating__stars[data-rating="4"] .fas:nth-child(2),
.rating__stars[data-rating="4"] .fas:nth-child(3),
.rating__stars[data-rating="4"] .fas:nth-child(4),
.rating__stars[data-rating="4.5"] .fas:nth-child(1),
.rating__stars[data-rating="4.5"] .fas:nth-child(2),
.rating__stars[data-rating="4.5"] .fas:nth-child(3),
.rating__stars[data-rating="4.5"] .fas:nth-child(4) {
  	color: #FFCA08;
}
.rating__stars[data-rating="5"] {
  	color: #FFCA08;
}
.zoomContainer{
	position: relative;
	z-index: 10;
}
.produto__detalhe__titulo{
	font-family: 'poppinsbold';
	font-size: 30px;
	line-height: 34px;
	color: #000;
	margin-bottom: 10px;


	@include media-breakpoint-down (sm) {
		font-size: 24px;
		line-height: 28px;
	}
}
.produto__detalhe__codigo{
	font-family: 'poppinslight';
	font-size: 12px;
	line-height: 14px;
	color: #000;
}
.produto__detalhe__avaliacao{
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.rating__stars{
		margin-left: 5px;
		display: flex;
		column-gap: 2px;
	}
}
.avaliacoes__quant{
	font-family: 'poppinslight';
	font-size: 12px;
	line-height: 14px;
	color: #000;
}
.row__produto__vendidos{
	margin-bottom: 0px;
	padding-top: 5px;

	& > * {
		margin-bottom: 10px;
	}
	.avaliacoes__quant{
		margin-right: 10px;
	}
}
.segura__vendidos{
	padding-top: 15px;
	border-top: 3px solid #ededed;
	margin-bottom: 60px;
}
.vendidos{
	text-align: right;
	font-size: 12px;
	line-height: 16px;
	font-family: poppinslight;
}
.cor__opcao{
    margin: 3px;

    .cor__input{
        position: fixed;
        left: -100vw;

        &:checked{
            & ~ .cor__label{
                border: 2px solid #000;
            }
        }
    }
    .cor__label{
        display: block;
        width: 24px;
        height: 24px;
        border: 2px solid transparent;
		cursor: pointer;
        margin: 0px;
        transition: all 0.3s linear;
        box-shadow: 0px 0px 5px rgba(#000, 0.5);
    }
}
.cor__titulo{
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-family: 'poppinssemibold';
    margin-bottom: 5px;
	text-align: left;
}
.produto__cor__listagem{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    flex-wrap: wrap;
}
.produto__comprar{
	margin-right: auto;
	margin-left: 0px;
	width: 100%;
    background-color: #ededed;
	border-radius: 5px;
}
.produto__detalhe__box{
	padding: 20px 36px 24px 32px;
	color: #000;
    border-bottom: 9px solid #FFF;
    text-align: center;

	.v-select-output{
		display: block;
		width: 100%;
		height: auto;
		padding: 16px 24px 18px 24px;
		border: none;
		background-color: #fff;
		font-size: 13px;
		line-height: 16px;
		color: #000;
		font-family: 'poppinsregular';
		resize: none;
		border: 1px solid #d1d1d1;
		border-radius: 0px;

		&:focus{
			box-shadow: none;
		}

		&:before{
			margin-top: 4px;
		}
	}
	.v-options{
		font-size: 13px;
		line-height: 15px;
		margin: none;
		max-height: calc(36px * 5);
		border-radius: 0px;
        z-index: 500;
		box-shadow: 0px 0px 5px rgba(#000, 0.3);
		background-color: #e4e4e4;

		li{
			color: #000;
			font-family: 'poppinsregular';
			padding: 15px 22px;

			&.selected{
				background-color: #d1d1d1;
				color: #000;
			}
		}
	}

    .row{

        @include media-breakpoint-down (md) {
            .col-md-6{
                margin-bottom: 15px;
            }
        }
    }
	&:first-child {
		border-radius: 0px;
	}
	&:last-child {
		border-radius: 0px;
	}

}
.produto__comprar{
	padding-bottom: 8px;

	.produto__detalhe__box{
		&:last-child{
			border-bottom: 0px;
		}
	}
}
.produto__detalhe__valor{
	display: flex;
	text-align: left;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: 0px;
	font-family: 'poppinsbold';
	font-size: 13px;
	line-height: 17px;
	color: #000;

	span{
		margin-right: 5px;
	}
}
.detalhe__dinheiro{
	font-size: 20px;
	font-family: 'poppinsbold';
	line-height: 24px;
	color: #000;
	margin-bottom: 5px;
}
.produto__detalhe__juros{
	font-family: 'poppinsregular';
	font-size: 14px;
	line-height: 16px;
	color: #000;
	text-align: left;
}
.produto__off{
	text-align: right;
	font-size: 20px;
	line-height: 22px;
	font-family: 'poppinsbold';
	color: #000;
}
.frete__detalhe{
	display: flex;
	position: relative;
	border: 1px solid #d1d1d1;
    margin-bottom: 3px;
    align-items: center;
    background: #fff;
	border-radius: 12px !important;
	overflow: hidden;

	&.esgotado__form{
		.frete__btn{
			// min-width: 120px;
			display: inline-block;
			white-space: nowrap;
		}
	}
}
.esgotado__titulo{
	font-family: 'poppinssemibold';
	font-size: 24px;
	line-height: 28px;
	color: #000;
}
.pesquisa__interna{
	.titulo__interna{
		margin-bottom: 60px;
	}
	.row{
		margin-bottom: 30px;
	}
}
.esgotado__desc{
	margin-bottom: 10px;
	color: #000;
}
.frete__input{
	display: block;
	width: 100%;
	background-color: #fff;
	color: #000;
	height: auto;
	padding: 14px 15px 14px 28px;
    font-family: 'poppinsregular';
	font-size: 13px;
	line-height: 17px;
	border: none;
    position: relative;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #000;
	}
}
.segura__voltagem__listagem,
.segura__tamanho__listagem,
.segura__cor__listagem{
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.esgotado__box{
	flex-direction: column;
	text-align: center;
	display: flex;
	align-items: center;

	.esgotado__titulo{
		margin-bottom: 5px;
	}

	.esgotado__form{
		width: 100%;
		flex-direction: column;
		text-align: center;
		display: flex;
		align-items: center;

		.frete__input{
			margin-bottom: 6px;
		}
	}

	.segura__esgotado{
		width: 100%;
		display: block;
		min-width: 100%;
	}

	.esgotado__form{
		flex-direction: column;
	}
}
.detalhe__tamanho__listagem{
	display: flex;
	flex-wrap: wrap;

	.tamanho__input{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .tamanho__label{
				background-color: #000;
				color: #fff;
                border: 1px solid #000;
			}
		}
	}
	.tamanho__label{
		min-width: 24px;
        width: auto;
        padding: 0px 5px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #202020;
		border-radius: 0px;
		margin: 0px 5px;
		font-size: 14px;
		line-height: 14px;
		transition: all 0.3s linear;
		margin-bottom: 10px;
		cursor: pointer;
		font-family: 'poppinsregular';
        margin-bottom: 0px;

		&:hover{
			background-color: #000;
			color: #fff;
		}
	}
    &.voltagem{
        .tamanho__label{
            font-size: 12px;
            line-height: 12px;
        }
    }
}
.caminhaozinho{
    display: inline-block;
    width: 18px;
    height: 13px;
    mask-image: url('../images/caminhaozinho.png');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: cover;
	background-color: currentColor;
    margin-right: 5px;
}
.frete__btn{
	font-family: 'poppinssemibold';
    color: #2d286b;
	font-size: 14px;
	line-height: 14px;
	border: none;
	background-color: #fff;
	padding: 0px 30px 0px 30px;
	position: relative;

	&:focus{
		outline: none;
	}
	&:hover{
		opacity: 1;
		color: #e78933;
		text-decoration: underline;
	}
}
.frete__link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
	padding-left: 0px;
	font-size: 13px;
	line-height: 17px;
	padding-left: 0px;
	color: #000;
    padding-top: 5px;
    font-family: 'poppinsregular';
	margin-left: 20px;

	.icon{
		margin-right: 5px;
		margin-bottom: 5px;
	}
	&:hover{
		color: #000;
	}
}
.produto__comprar,
.produto__indisponivel{
	margin-bottom: 20px;
}
.sociais__produto__texto{
    margin-bottom: 5px;
    font-family: 'poppinsregular';
    color: #000;
    font-size: 13px;
    line-height: 17px;
}
.produto__sociais{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	column-gap: 5px;
	row-gap: 5px;
	padding-top: 30px;
}
.row__produto__interna{
    padding-bottom: 50px;

    .col-lg-6{
        &:first-child{
            position: relative;
            z-index: 20;
        }
        &:last-child{
            position: relative;
            z-index: 10;
        }
    }
}
.contato__btn__center{
	display: flex;
	justify-content: center;
}
.quantidade__detalhes{
	display: flex;
	align-items: center;
}
.segura__tab{
    padding-bottom: 50px;
    border-bottom: 3px solid #ededed;
    margin-bottom: 40px;
	padding-top: 50px;
	border-top: 3px solid #ededed;

	@include media-breakpoint-down (sm) {
		padding-bottom: 25px;
		padding-top: 25px;
		margin-bottom: 20px;
	}
}
.segura__download__produto{
    padding-left: 30px;
}
.tab__produto{

	.nav-tabs{
        border-bottom: none;
        flex-wrap: wrap;
		position: relative;
		z-index: 10;
		align-items: flex-end;
		min-height: 50px;
	}
	.tab-content{
		position: relative;
		z-index: 15;

		iframe{
			max-width: 100%;
			height: auto;
		}
	}
	.nav-item{
		&:first-child {
			.nav-link{
				border-radius: 5px 0px 0px 0px;
			}
		}
		&:last-child{
			.nav-link{
				border-radius: 0px 5px 0px 0px;
			}
		}
	}
	.nav-link{
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 50px;
        padding: 12px 10px 10px 10px;
		background-color: #dbdbdb;
		color: #000;
		font-size: 16px;
		line-height: 20px;
		transition: all 0.3s linear;
		font-family: 'poppinssemibold';
        margin: 0px;
        min-width: 140px;
        text-align: center;
        border-radius: 0px;
		transition: all 0.3s linear;

		&.active{
			background-color: #ededed;
            color: #000;
		}

		@include media-breakpoint-down (sm) {
			min-width: 100px;
			font-size: 12px;
			line-height: 14px;
		}
	}
	.tab-content{
		padding: 50px 30px 55px 30px;
        background-color: #ededed;
        color: #000;
		border-radius: 0px 0px 5px 5px;
		font-family: poppinslight;

		@include media-breakpoint-down (sm) {
			padding: 25px 25px 25px 25px;
		}
	}
}

.cor__opcao{
    margin: 3px;

    .cor__input{
        position: fixed;
        left: -100vw;

        &:checked{
            & ~ .cor__label{
                border: 2px solid #373435;
            }
        }
    }
    .cor__label{
        display: block;
        width: 24px;
        height: 24px;
        border: 2px solid transparent;
		cursor: pointer;
        margin: 0px;
        transition: all 0.3s linear;
        box-shadow: 0px 0px 5px rgba(#373435, 0.5);
    }
}

.produto__cor__listagem{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    flex-wrap: wrap;
}
.titulo__avaliacoes{
	font-family: 'poppinssemibold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	margin-bottom: 16px;
}
.estrela__media{
	.avaliacao__media{
		font-family: 'poppinssemibold';
		font-size: 30px;
		line-height: 34px;
		color: #000;
		margin-right: 15px;


	@include media-breakpoint-down (sm) {
		font-size: 24px;
		line-height: 28px;
	}
	}
	.numero__avaliacoes{
		color: #000;
		font-size: 12px;
		line-height: 12px;
		margin-left: 10px;
	}
}
.avaliacao__card{
	padding: 32px 26px;
	border: 1px solid #d1d1d1;
	border-radius: 5px;
	margin-bottom: 10px;
	color: #000;

	.avaliacao__header{
		margin-bottom: 10px;

		.numero__avaliacoes{
			color: #000;
			font-size: 12px;
			line-height: 12px;
			margin-left: 10px;
		}
	}
}
.comentario{
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-family: poppinslight;
}
.comentario__nome{
    color: #000;
    font-family: 'poppinssemibold';
    font-size: 15px;
    line-height: 19px;
}
.produto__tab__titulo{
	font-family: 'poppinssemibold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
	color: #000;
}
.avaliar__btn{
	width: 126px;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding-top: 2px;
	color: #000;
	font-size: 13px;
	line-height: 13px;
	background-color: #fff;
	border: 2px solid #000;
	font-family: 'poppinsregular';
	transition: all 0.3s linear;

	&:hover{
        background-color: #000;
        color: #fff;
	}

	&:focus{
		outline: none;
	}
}
.avaliacao__radio{
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.mobile__titulo{
	@include media-breakpoint-up (lg) {
		display: none;
	}
}
.row__produto__interna{
	@include media-breakpoint-down (md) {
		.col-lg-6{
			.produto__detalhe__titulo{
				display: none;
			}
		}
	}
}
.relacionados{
	padding-top: 0px;
	padding-bottom: 50px;
	border-bottom: 5px solid #ededed;
	margin-bottom: 50px;

	.titulo__secao{
		margin-bottom: 30px;
	}
}
.avaliacao__sub{
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 15px;
    font-family: 'poppinssemibold';
    color: #000;
	padding-top: 11px;
}
.label__avaliacao{
	font-size: 14px;
	line-height: 16px;
	color: #000;
	position: relative;
	padding-left: 24px;
    padding-top: 2px;
    margin-right: 10px;
    font-family: 'poppinsmedium';
    min-height: 20px;

	&:hover{
		cursor: pointer;

		&:before{
			opacity: 0.5;
		}
	}

	&:after{
		content: '';
		display: block;
		width: 20px;
		min-width: 20px;
		height: 20px;
		border: 2px solid #000;
		border-radius: 50%;
		position: absolute;
		left: 0px;
		top: 0px;
		transition: all 0.3s linear;
	}
    &:before{
        content: '';
        width: 20px;
        height: 20px;
        background-color: #000;
        border: 4px solid #fff;
        border-radius: 50%;
        top: 0px;
        left: 0px;
        transition: all 0.3s linear;
        opacity: 0;
        position: absolute;
    }
}
.radio__avaliacao{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .label__avaliacao{
			&:before{
				opacity: 1;
				background-color: #000;
			}
			&:after{
				border-color: #000;
			}
		}
	}
}
.avaliacao__header{
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 10px;
}
.estrela__media{
	display: flex;
	align-items: center;
	padding-bottom: 0px;
	margin-bottom: 15px;

	.rating__stars{
		font-size: 20px;
		line-height: 20px;
	}
}
.avaliacao__input{
	display: block;
	width: 100%;
	padding: 18px 24px 16px 24px;
    border: none;
    background-color: #fff;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    font-family: 'poppinsregular';
    resize: none;
	border: 1px solid #d1d1d1;
	border-radius: 5px;

	resize: none;
	color: #000;
	&::placeholder{
		color: #000;
	}

	&:focus{
		outline: none;
	}
}
.avaliacao__escreva{
	font-family: 'poppinsregular';
    font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #000;
	margin-bottom: 10px;
}
.avaliacao__titulo{
	font-family: 'poppinsregular';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	margin-bottom: 25px;
}
.segura__avaliar__btn{
    display: flex;
    justify-content: flex-end;
	padding-top: 15px;
}
.segura__frete__detalhe{
	display: flex;
	align-items: center;
	border-radius: 0px;
}
.produto__download{
    display: flex;
    align-items: center;
    color: #000;

    &:hover{
        color: #000;
    }

    .produto__download__icone{
        margin-right: 10px;
    }
}
.frete__detalhe{
	flex-grow: 1;
	overflow: hidden;
	border: 1px solid #d1d1d1;
	border-radius: 0px;
}
.visualizar__carousel{
	text-align: center;

	.carousel-indicators{
		position: relative;
		text-indent: 1;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-left: 0px;
		margin-right: 0px;
		padding-top: 20px;

		li{
			text-indent: 1px;
			width: 54px;
			height: 54px;
			min-width: 54px;
			opacity: 1;
			position: relative;
			margin: 0px 10px;
			border: none;

			&.active{
				&:after{
					opacity: 0.6;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
                left: 0px;
                background-color: #000;
				opacity: 0;
				transition: all 0.3s linear;
			}

		}
	}
}
.produto__detalhes__botoes{
	display: flex;
	justify-content: center;
}
.produto__detalhe__botao{
	display: flex;
	width: 100%;
}
.sociais__produto{
    margin-bottom: 45px;
    padding-top: 30px;

	.sociais__rodape{
		a{
			border-radius: 50%;

			&:after{
				border-radius: 50%;
			}
		}
	}
}
.unidade__input{
	display: block;
	max-width: 100%;
	width: 30px;
	margin-right: 5px;
	text-align: center;
}
.item__capacidade{
	.row{
		align-items: center;
	}
	.col-3{
		label{
			display: flex;
			align-items: center;
			font-size: 12px;
			line-height: 14px;
			margin-bottom: 0px;
		}
	}
	.col-9{
		font-size: 15px;
		line-height: 18px;
	}
}
.avaliacoes__vend{
	text-align: right;
	font-size: 12px;
	line-height: 14px;
}
.sociais__produto__span{
    font-family: 'poppinsregular';
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}
.sociais__produto__nav{
    display: flex;

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
		border: none;
        border-radius: 50%;
        transition: all 0.3s linear;

        &:hover{
            text-decoration: none;
            background-color: #000;
            color: #fff;
        }
    }
}
.check__produto{
	margin-bottom: 10px;
	vertical-align: middle;

	.icon{
		margin-right: 5px;
	}
}
.frete__bolinha{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	margin-left: 15px;
	background-color: #000;
	color: #fff;
	font-family: 'poppinssemibold';
	padding-top: 1px;
	font-size: 16px;
	line-height: 16px;
}
#modal__configuracao{
	.modal-dialog{
		width: 100%;
		max-width: 1110px;
	}
	.modal-content{
		padding: 10px 30px 50px 30px;

		.modal-header,
		.modal-body{
			padding: 0px;
			border: none;
		}
		.modal-header{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 5px;
		}
	}
	.titulo__secao{
		margin-bottom: 10px;
	}
	.configuracao__desc{
		text-align: justify;
		margin-bottom: 22px;
	}
	.form-group{
		margin-bottom: 8px;

		&:nth-child(5) {
			grid-column: 1 / 3;
		}
	}
	.configuracao__btn{
		grid-column: 1 / 3;
		display: flex;
		justify-content: center;
		padding-top: 10px;
	}
}
.carrinho__modal{
	.modal-dialog{
		max-width: 730px;
	}

	.titulo__login{
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid #000;
	}
	.modal-content{
		border-radius: 15px;
	}
	.segura__botao__modal{
		button{
			border: none !important;
			opacity: 0.5;

			&:hover{
				opacity: 1;
			}
		}
	}
	.modal-body{
		padding-left: 0px;
		padding-right: 0px;
	}
	.carrinho__produto__img{
		position: relative;
	}

	.carrinho__modal__btns{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;

		& > * {
			margin: 10px;
		}
	}

	.carrinho__card{
		border-radius: 0px;
		border-left: unset !important;
		border-right: unset !important;
		border-top: unset !important;
		align-items: center;
		margin-left: 30px;
		margin-right: 30px;

		.col-lg-3{
			text-align: right;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		.excluir__modal{
			margin-bottom: 20px;
			text-align: center;
			padding: 0px;
			background-color: transparent;
			border: none;
		}
	}

	.btn__excluir{
		right: -10px;
		left: unset !important;
		opacity: 1 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(#000, 1);
		font-family: poppinssemibold;
	}

	&.produto__modal{
		.modal-dialog{
			max-width: 1100px;
		}
		.modal-body{
			padding: 15px;
		}
	}
	.row__produto__interna{
		padding-bottom: 0px;
	}
}
.link__produto__centro{
	font-family: poppinssemibold;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	display: block;
	margin-bottom: 30px;
}
.btn__fechar{
	font-family: 'poppinsregular';
	font-size: 30px;
	line-height: 30px;
	color: #000;
	padding: 0px;
	border: none;
	background-color: transparent;


	@include media-breakpoint-down (sm) {
		font-size: 24px;
		line-height: 28px;
	}

	&:focus{
		outline: none;
	}
}
.configuracao__form{
	display: grid;
	grid-template-columns: 50% 50%;
	column-gap: 20px;
}
.produto__detalhes__carrinho{
	display: grid;
	grid-template-columns: 200px 1fr;
	column-gap: 20px;
	align-items: center;

	.titulo__quantidade__carrinho{
		margin-bottom: 0px;
	}

	.quantidade__detalhes{
		display: flex;
		align-items: center;
		text-align: left;
	}
	& > *{
		width: 100%;
	}

	@include media-breakpoint-down (md) {
		display: flex;
		flex-direction: column;

		.quantidade__detalhes{
			margin-bottom: 10px;

			& > *{
				margin-bottom: 10px;
			}
		}
	}
	.produto__comprar__btn__card{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border: none;
		padding: 5px 0px;
		background-color: #2d286b;
		color: #fff;
		border: none;
		font-family: poppinssemibold;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		min-width: 150px;
		height: 50px;
		padding: 5px 15px;
		border-radius: 5px;
		transition: all 0.3s linear;
		&:hover{
			background-color: #e78933;
			color: #fff;
			text-decoration: none;
		}

		.icon__mask{
			margin-left: 5px;
		}
	}
}
@include media-breakpoint-up (xl) {
	.row__produto__interna{
		.col-lg-6{
			&:last-child{
				padding-left: 20px;
			}
		}
	}
}
@include media-breakpoint-up (lg) {
	.segure__download__btn{
		justify-content: flex-end;
	}
	.visualizar__mobile{
		display: none;
	}
}
@include media-breakpoint-down (md) {
	.produto__figure{
		text-align: center;
		margin-bottom: 20px;
	}
	.row__produto__interna{
		.col-lg-6{
			&:first-child{
				margin-bottom: 30px;
			}
		}
	}
	.angulos__txt{
		text-align: center;
	}
	.row__miniaturas{
		justify-content: center;
		margin-bottom: 30px;

		.col__auto{
			&:first-child{
				margin-left: 10px;
			}
		}
	}
	.produto__indisponivel,
	.produto__comprar{
		max-width: 100%;
		width: 100%;
	}
	.avaliacao__form{
		padding-top: 30px;
	}
	// .row__produto__interna{
	// 	.col-lg-6{
	// 		&:first-child{
	// 			order: 2;
	// 		}
	// 		&:last-child{
	// 			order: 1;
	// 		}
	// 	}
	// }
	.produto__sociais{
		justify-content: center;
		margin-bottom: 30px;
	}
	.sociais__span{
		text-align: center;
	}
	.visualizar__desktop{
		display: none;
	}
}
@include media-breakpoint-down (sm) {
	.titulo__gostar{
		&:after{
			display: none;
		}
	}
}
@include media-breakpoint-down (xs) {
	.produto__detalhe__box{
		flex-direction: column;

		&:first-child{
			.produto__valor__box{
				justify-content: center;
				align-items: center;

				&:first-child{
					width: 100%;
					margin-bottom: 10px;
					padding-bottom: 10px;
					border-bottom: 2px solid #d1d0d0;
					border-right: 0px;

				}
				&:last-child{
					width: 100%;
				}
			}
		}
	}
	.produto__detalhes__botoes{
		flex-direction: column;
		align-items: center;

		.produto__detalhe__botao{
			margin: 5px;
		}
	}
	.configuracao__form{
		display: block;
	}
}
.segura__relacionados__produtos{
	padding-top: 40px;
	border-top: 4px solid #ececec;
}
.custom__modal{
	.modal-dialog{
		.modal-content{
			.segura__botao__modal{
				display: flex;
				justify-content: flex-end;
				margin-bottom: 10px;

				button{
					font-size: 20px;
					line-height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 40px;
					height: 40px;
					color: #000;
					border: 2px solid #000;
					border-radius: 50%;
					background-color: transparent;
				}
			}
			.esgotado__modal__conteudo{
				text-align: center;
			}
			.segura__esgotado{
				margin-bottom: 15px;
			}
		}
	}
	&.popup{
		.modal-dialog{
			max-width: 1000px;

			.esgotado__titulo{
				margin-bottom: 20px;
			}
		}
	}
}
.checkbox__custom{
	margin-bottom: 10px;
	input{
		position: fixed;
		left: -200vw;

		&:checked{
			~ label{

				&:before{
					border-color: #000;
				 }
				&:after{
					opacity: 1;
				}
			}
		}
	}
	label{
		position: relative;
		margin-bottom: 0px;
		padding-left: 25px;
		display: block;
		margin-left: 10px;
		cursor: pointer;
		color: #000;
		font-family: 'poppinsregular';
		font-size: 13px;
		line-height: 13px;
		text-align: left;

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border: 2px solid #000;
			transition: all 0.3s linear;
			background-color: transparent;
			z-index: 10;
		}

		&:after{
			content: '';
			position: absolute;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border: 4px solid #fff;
			background-color: #000;
			top: 50%;
			left: 0px;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			opacity: 0;
			z-index: 5;
		}
	}
}
.destaque__imagem{
	text-align: center;

	a{
		max-width: 655px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
}
.owl__destaque.owl-carousel{
	.owl-nav{
		display: flex;
		justify-content: center;

		button.owl-prev,
		button.owl-next{
			border: none;
			font-size: 20px;
			line-height: 20px;
			background-color: transparent;
			margin: 0px 5px;
		}
	}
	margin-top: 20px;
	a{
		input{
			position: fixed;
			top: -200vw;
		}
	}
	label{
		cursor: pointer;
	}
}
.video__interna__produto{
	padding: 50px 0px;
	border-top: 5px solid #ededed;
	border-bottom: 5px solid #ededed;

	.embed-responsive{
		max-width: 730px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		border-radius: 10px;
		overflow: hidden;
	}
}
