.rodape__conteudo{
	background-color: #2d286b;
	color: #fff;
	.rodape__cima{
		padding: 30px 0px 75px 0px;
		border-bottom: 1px solid rgba(#fff, 0.2);

		@include media-breakpoint-down (md) {
			padding: 30px 0px;
		}
	}
	.rodape__meio{
		padding: 25px 0px;
		border-bottom: 1px solid rgba(#fff, 0.2);

		@include media-breakpoint-down (md) {
			padding: 30px 0px;
		}
	}
	.rodape__baixo{
		padding: 35px 0px;

		@include media-breakpoint-down (md) {
			padding: 30px 0px;
		}
	}
}
.rodape__logo{
	margin-bottom: 15px;
}
.rodape__desc{
	font-family: poppinslight;
	margin-bottom: 40px;
	letter-spacing: -0.5px;
	font-size: 14px;
	line-height: 24px;
}
.rodape__nav{
	display: flex;
	flex-wrap: wrap;
	column-gap: 15px;
	row-gap: 15px;
	font-size: 30px;
	line-height: 30px;

	@include media-breakpoint-down (md) {
		margin-bottom: 30px;
	}

	a{
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			color: #e78933;
		}
	}
}
.rodape__titulo{
	margin-bottom: 24px;
	font-family: poppinssemibold;
	font-size: 16px;
	line-height: 20px;

	@include media-breakpoint-up (lg) {
		padding-top: 80px;
	}
}
.rodape__atendimento{
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 13px));
	column-gap: 25px;

	@include media-breakpoint-down (xs) {
		grid-template-columns: repeat(1, 1fr);
	}

	& > *{
		margin-bottom: 28px;
	}
}
.rodape__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-family: poppinslight;

	@include media-breakpoint-down (md) {
		margin-bottom: 30px;
	}

	li{
		margin-bottom: 6px;
	}
}
.atend__item{
	font-family: poppinslight;
	letter-spacing: -0.5px;
}
.atend__item__titulo{
	font-family: poppinssemibold;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 6px;
}
.info__card{
	display: flex;
	align-items:center;
	column-gap: 15px;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	font-family: poppinslight;

	@include media-breakpoint-down (md) {
		margin-bottom: 15px;
	}


	.info__titulo{
		font-family: poppinssemibold;
		font-size: 16px;
		line-height: 20px;
	}
}
.news__titulo{
	font-family: poppinssemibold;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 15px;
}
.news__desc{
	font-family: poppinslight;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -1px;

	@include media-breakpoint-down (lg) {
		margin-bottom: 10px;
	}
}
.news__input{
	display: block;
	width: 100%;
	border: none;
	padding: 15px 22px;
	min-height: 50px;
	border-radius: 5px;
	background-color: rgba(#fff, 0.1);
	border: 1px solid #fff;
	color: #fff;

	&::placeholder{
		color: #fff;
	}
}
.news__form{
	display: grid;
	grid-template-columns: 1fr 1fr 122px;
	column-gap: 20px;

	@include media-breakpoint-down (sm) {
		display: block;
	}
}
.news__btn{
	width: 100%;
	background-color: #e78933;
	color: #fff;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-family: poppinssemibold;
	font-size: 13px;
	line-height: 15px;
	border: none;
	transition: all 0.3s linear;

	&:hover{
		background-color: #fff;
		color: #e78933;
	}
}
.rodape__creditos{
	background-color: #e78933;
	color: #fff;
	font-size: 12px;
	line-height: 14px;
	padding: 30px 0px 24px 0px;

	.creditos{
		line-height: 18px;
	}

	.container{
		display: flex;
		justify-content: space-between;

		@include media-breakpoint-down (md) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;

	svg{
		margin-block-start: 5px;
	}
}
@include media-breakpoint-up (lg) {
	.rodape__cima{
		display: flex;
		justify-content: space-between;
		column-gap: 15px;

		.col__1{
			max-width: 265px;
			width: 100%;

			@include media-breakpoint-only (lg) {
				max-width: 250px;
			}
		}
		.col__2{
			max-width: 280px;
			width: 100%;

			@include media-breakpoint-only (lg) {
				max-width: 220px;
				.rodape__atendimento{
					display: block;
				}
			}
		}
		.col__3{
			max-width: 140px;
			width: 100%;
		}
		.col__4{
			max-width: 165px;
			width: 100%;
		}
		.col__5{
			max-width: 190px;
			width: 100%;
		}
	}
	.info__grid{
		display: flex;
		justify-content: space-between;
		column-gap: 20px;
	}

}
@include media-breakpoint-only (xl) {
	.rodape__baixo{
		.news__grid{
			display: grid;
			grid-template-columns: 1fr 740px;
			column-gap: 30px;
			align-items: center;

			.form-group{
				margin-bottom: 0px;
			}
		}
	}
}
