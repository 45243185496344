.categoria__interna{
	.paginacao{
		justify-content: center;
	}
	.col-lg-3{
		margin-bottom: 40px;
	}
}
.categoria__interna__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.filtros__categorias{
	display: flex;
	justify-content: center;
	margin-bottom: 50px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
	}

	.segura__filtro{
		@include media-breakpoint-up (lg) {
			width: 50%;
			position: relative;

			&:after{
				width: 2px;
				height: 30px;
				background-color: #c7c7c7;
				position: absolute;
				content: '';
				top: calc(50% - 15px);
				right: -1px;
			}
		}

		.v-select-output{
			display: flex;
			justify-content: center;
			border-radius: 0px;
			border: none;
			border-top: 1px solid #c7c7c7;
			border-bottom: 1px solid #c7c7c7;
			font-family: 'poppinsbold';
			color: #000;
			padding-top: 20px;
			padding-bottom: 20px;
			height: auto;

			&:before{
				float: none;
				order: 2;
				border-left-width: 0.4em;
				border-right-width: 0.4em;
				border-top-width: 0.4em;
			}
		}
	}
}
.box__filtro__ordenacao{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;

	@include media-breakpoint-up (lg) {
		& > * {
			width: 50%;
		}
	}

	@include media-breakpoint-down(md){
		flex-direction: column;
		align-items: flex-start;

		& > *{
			width: 100%;
			margin-bottom: 10px;


		}
		.filtro__direita{
			& > *{
				margin-bottom: 10px;
			}
		}
	}

	.filtro{
		position: relative;
		width: 100%;

		@include media-breakpoint-down(sm){
			margin-right: 0;
		}
	}

}
.filtro__esquerda{
	.categoria__lista{
		padding-left: 0px;
	}
	.btn__filtro,
	.dropdown{
		@include media-breakpoint-up (lg) {
			max-width: 470px;
		}
	}
}
.filtro__direita{


	@include media-breakpoint-up (lg) {
		display: grid;
		grid-template-columns: calc(40% - 10px) calc(60% - 10px);
		column-gap: 20px;
	}
}
.btn__filtro,
.btn__ordem{
	background-color: transparent;
	width: 100%;
	color: #fff;
	font-family: poppinssemibold;
	display: flex;
	justify-content: space-between;
	padding: 12px 17px;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	border-radius: 5px;
	border: none;
	background-color: #2d286b;
	min-height: 50px;

	@include media-breakpoint-down (md) {
		min-height: 36px;
		font-size: 13px;
		line-height: 15px;
	}

	&:hover{
		color: #fff;
	}
}
.box__mid{
	.categoria__box{
		margin-bottom: 20px;
	}
}
.row__categ{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
	row-gap: 20px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (xs) {
		column-gap: 10px;
		row-gap: 10px;
	}
}

.filtro__btn__segura{
	text-align: center;
}
.categoria__header{
	background-color: #000;
	color: #fff;
	font-family: 'poppinsregular', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	padding: 16px 20px 14px;
}
.titulo__categoria{
	font-family: poppinsbold;
	font-size: 15px;
	line-height: 18px;
	color: #000;
	margin-bottom: 14px;
}

.btn__filtrar{
	display: block;
	width: 100%;
	max-width: 120px;
	font-size: 13px;
	line-height: 15px;
	padding: 8px 10px;
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	background-color: #2d286b;
	color: #fff;
	font-family: poppinssemibold;
	margin-bottom: 15px;
	transition: all 0.3s linear;

	&:hover{
		color: #fff;
		text-decoration: none;
		background-color: #e78933;
	}
}
.dropdown-menu-filtro,
.dropdown-menu{
	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
		padding-top: 10px;
		padding-bottom: 10px;

		&.subcategoria__lista{
			padding-top: 5px;
			padding-bottom: 5px;
		}

		.categoria__item__interna{
			display: flex;
			justify-content:space-between;
			padding: 5px 15px;
			align-items: center;
			font-size: 13px;
			line-height: 13px;
			padding-left: 0px;

			&:hover{
				text-decoration: none;
			}
		}

		li{
			&:last-child{
				a{
					border-radius: 0px 0px 15px 15px;
				}
			}
		}

		.segura__categoria__controls{

			position: relative;
			.categoria__item__interna{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 5px 15px;
				padding-right: 40px;
				padding-left: 0px;


				&:hover{
					text-decoration: none;
				}
			}
			button{
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.categoria__box{

		@include media-breakpoint-down(lg){
			margin-right: 20px;
		}
	}
}

.categoria__lista{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;

	@include media-breakpoint-down(md){
		margin-bottom: 10px;
	}
}
.categoria__quant{
	margin-left: 5px;
}
.categoria__mais{
	border: none;
	background-color: transparent;
	padding: 0px;
	font-family: 'poppinsregular', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 15px;
	color: #000;
	margin-top: 5px;
	display: inline-block;

	@include media-breakpoint-down(md){
		margin-bottom: 30px;
	}

	span{
		font-size: 12px;
		line-height: 14px;
	}

	&:focus{
		outline: none;
	}
}
.categoria__segura__radio{
	margin-bottom: -5px;
	.categoria__radio{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .categoria__label{

				&:before{
					border-color: #000;
					background-color: #fff;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.categoria__label{
		padding-left: 20px;
		position: relative;
		font-family: 'poppinsregular', sans-serif;
		font-weight: 300;
		font-size: 14px;
		line-height: 18px;
		color: #000;
		transition: all 0.3s linear;
		cursor: pointer;

		&:hover{

			&:before{
				border-color: #000;
				background-color: #fff;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 30%;
			transform: translateY(-50%);
			left: 0px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #000;
			transition: all 0.3s linear;
			margin-top: 2px;
		}

		&:after{
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #000;
			top: 30%;
			left: 3px;
			transform: translateY(-1px);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.categoria__interna{
	.col-xl-9{
		margin-bottom: 10px;

		.titulo__interna{
			margin-bottom: 10px;
		}

		.col-lg-6{
			margin-bottom: 20px;
		}
	}
	.paginacao{
		padding-top: 30px;
	}
}
.categoria__mais{
	&[aria-expanded="true"] {
		&.categ__mais{
			display: none;
		}
	}
	&[aria-expanded="false"] {
		&.categ__menos{
			display: none;
		}
	}
}
.subcategoria__lista{

	.subcategoria__item{

		.categoria__item__interna{
			font-size: 12px;
			line-height: 14px;
		}
	}
}
.segura__categoria__controls{
	display: flex;
	align-items: center;

	button{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		background-color: #000;
		color: #fff;
		border-radius: 50%;
		border: none;
		min-width: 18px;
		min-height: 18px;
		width: 18px;
		height: 18px;
		padding: 0px;
		font-size: 8px;
		line-height: 8px;
		margin-left: 5px;
		margin-bottom: 2px;
		transition: all 0.3s linear;
		padding-top: 1px;

		&:focus{
			outline: none;
		}
		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}
.box__filtro__ordenacao{
	.dropdown{
		&.show{
			.btn__filtro,
			.btn__ordem{
				position: relative;
				z-index: 1050;
				background: #e78933;
				color: #fff;

				&:after{
					transform: rotate(90deg);
				}
			}
		}

		.dropdown-menu{
			z-index: 1000;
			border-radius: 0;
			box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			-webkit-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			width: 100%;
			left: 0px !important;
			transform: none !important;
			top: calc(100% - 20px) !important;
			padding-top: 30px;
			border-radius: 0px 0px 15px 15px;

			.drop-content{
				padding: 20px;

				&.gridado{
					grid-template-columns: repeat(2, 1fr);
					column-gap: 10px;
					display: grid;
				}

				.categoria__box{
					width: 100%;
				}

				@include media-breakpoint-down(md){
					flex-direction: column;
					align-items: flex-start;
				}
			}


		}
		.box__filtro__ordenacao{
			position: relative;
		}
	}
}
