.interna{
    padding: 120px 0px;

	@include media-breakpoint-down (md) {
		padding: 80px 0px;
	}
}
.titulo__interna{
    font-family: 'poppinsbold';
    font-size: 30px;
    line-height: 34px;
    color: #000;
    margin-bottom: 50px;
	position: relative;
	text-align: left;
	padding-bottom: 3px;
	border-bottom: 3px solid #e78933;

	@include media-breakpoint-down (sm){
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 30px;
	}

	&.alt{
		margin-bottom: 40px;

		@include media-breakpoint-down (sm){
			margin-bottom: 20px;
		}
	}
}
.btn__center{
	display: flex;
	justify-content: center;
	padding-top: 40px;
}
.conteudo__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: transparent;
	border: none;
	background-color: #2d286b;
	min-width: 250px;
	height: 50px;
	transition: all 0.3s linear;
	font-family: 'poppinssemibold';
	font-size: 14px;
	line-height: 16px;
	border-radius: 5px;
	position: relative;
	transition: all 0.3s linear;
	padding: 3px 15px;

	@include media-breakpoint-down (sm) {
		height: 40px;
		font-size: 13px;
		line-height: 15px;
		border-radius: 10px;
		min-width: 180px;
	}

	&.produto__detalhe__botao{
		min-width: 100% !important;
	}


	span{
		position: relative;
		z-index: 200;
	}

	&.voltar__btn{
		min-width: 140px;
	}

	&:focus{
		outline: none;
	}

	&:hover{
		color: #fff;
		text-decoration: none;
		background-color: #e78933;
	}
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#353535, 0.4);
        width: 100%;
		text-align: center;
    }
}
.conta__interna{
	.titulo__interna{
		text-align: center;
	}
}
.contato__btn__center{
	display: flex;
	justify-content: center;
}

.custom__check__input{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .custom__check__label{
			&:after{
				opacity: 1;
			}
		}
	}
}
.custom__check__label{
	position: relative;
	padding-left: 22px;
	cursor: pointer;
	font-family: poppinsbold;

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 2px solid currentColor;
		transition: all 0.3s linear;
	}

	&:after{
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: currentColor;
		top: 50%;
		left: 5px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.checkbox__custom{
	padding-top: 10px;
}
.segura__contato__btn{
	display: flex;
	align-items: center;
	justify-content: center;
}
.paginacao{
	display: flex;
	justify-content: center;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 30px;

	.paginacao__item{
		a{
            font-family: 'poppinsbold';
            font-size: 15px;
            line-height: 15px;
            color: #fff;
            display: flex;
            width: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 35px;
			min-width: 35px;
            position: relative;
            transition: all 0.3s linear;
			border: none;
			background-color: #2d286b;
			border: none;
            margin: 0px 2px;
            padding: 0px;
			@include media-breakpoint-down (sm) {
				width: 30px;
				height: 30px;
				min-width: 30px;
				font-size: 15px;
				line-height: 15px;
			}
            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				color: #fff;
				background-color: #e78933;
                text-decoration: none;
				pointer-events: none;

				&:hover{
					background-color: #e78933;
				}
           }
        }

		&:hover{
			a{
                text-decoration: none;
				background-color: darken( #2d286b, 5%);
            }
		}
	}
}
.contato__interna__endereco{
	span{
		font-family: poppinsbold;
		color: #2d286b;
		margin-bottom: 5px;
		display: inline-block;
	}


}

.modal__custom{
	.modal-dialog{
		max-width: 600px;
		width: 100%;

		.modal-content,
		.modal-body,
		.modal-header{
			border: none;
			background-color: transparent;
			padding: 0px;
		}
		.modal-header{
			padding: 10px 0px;
			display: flex;
			justify-content: flex-end;

			button{
				padding: 0px;
				background-color: transparent;
				color: #fff;
				border: none;
				font-family: poppinsregular;
				font-size: 13px;
				line-height: 15px;
			}
		}
		.modal-body{
			padding: 70px 15px 70px;
			background-color: #2d286b;
			color: #fff;
			text-align: center;
			border-radius: 5px;
			box-shadow: 0px 10px 15px rgba(#000, 0.2);

			.modal__icone{
				margin-bottom: 25px;
			}
			.modal__titulo{
				font-family: poppinslight;
				font-size: 35px;
				line-height: 38px;
				color: #fff;
				text-align: center;
				max-width: 480px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 15px;

				span{
					font-family: poppinssemibold;
				}
			}
			.modal__finalizar__titulo{
				font-size: 35px;
				line-height: 39px;
				color: #fff;
				margin-bottom: 20px;
				font-family: poppinsmedium;
				max-width: 340px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}
			.modal__finalizar__btns{
				display: flex;
				flex-direction: column;
				row-gap: 10px;
				max-width: 460px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;

				& > *{
					background-color: #fff;
					color: #2d286b;

					&:hover{
						background-color: #e78933;
						color: #fff;
					}
				}
			}
		}

	}
	&.alt{
		.modal-body{
			background-color: #2d286b;
		}
	}
}
.modal__video{
	.modal-dialog{
		max-width: 1100px;
		width: 100%;

		.modal-content,
		.modal-body,
		.modal-header{
			border: none;
			background-color: transparent;
			padding: 0px;
		}
		.modal-header{
			padding: 10px 0px;
			display: flex;
			justify-content: flex-end;

			button{
				padding: 0px;
				background-color: transparent;
				color: #fff;
				border: none;
				font-family: poppinsregular;
				font-size: 13px;
				line-height: 15px;
			}
		}
	}
}
.v-file-input{
	height: 50px;
	@include media-breakpoint-down (sm) {
		height: 40px;
		font-size: 13px;
		line-height: 15px;
		border-radius: 5px;
		min-width: 180px;
	}
}
.v-file-label{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: transparent;
	border: none;
	background-color: #2d286b;
	max-width: 250px;
	height: 50px;
	transition: all 0.3s linear;
	font-family: 'poppinssemibold';
	font-size: 14px;
	line-height: 16px;
	border-radius: 5px;
	transition: all 0.3s linear;
	padding: 3px 40px;
	margin-bottom: 0px;
	text-align: center;

	.v-file-button{
		background-color: transparent;
		padding: 0px;
		margin-right: 10px;
	}

	@include media-breakpoint-down (sm) {
		height: 40px;
		font-size: 13px;
		line-height: 15px;
		border-radius: 5px;
		min-width: 180px;
		padding: 3px 20px;
		max-width: 180px;
	}
}
.contato__btn__grid{
	display: flex;
	flex-wrap: wrap;
	column-gap: 10px;
	row-gap: 10px;
	padding-top: 30px;
	justify-content: center;

	& > *{
		max-width: 250px;
	}

	.form-group{
		margin-bottom: 0px;

		@include media-breakpoint-down (sm) {
			min-width: 180px;
			max-width: 180px;
		}
	}

	@include media-breakpoint-down (xs) {
		flex-direction: column;
		align-items: center;
		.form-group{
			order: 1;
		}
		.segura__contato__btn{
			order: 2;
		}
	}
}
.servico__grid{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
	row-gap: 20px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (xs) {
		column-gap: 10px;
		row-gap: 10px;
	}
}
.interna__servicos{
	.solucao__conteudo{
		box-shadow: 0px 0px 5px rgba(#000, 0.5) !important;
	}
	.solucao__icone{
		box-shadow: 0px 4px 3px rgba(#000, 0.2) !important;
	}
}
.missao__grid{
	padding-top: 40px;
	@include media-breakpoint-up (md) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 15px;
		justify-content: space-between;

		& > *{
			max-width: 310px;

			&:nth-child(2) {
				margin-left: auto;
				margin-right: auto;
			}
			&:nth-child(3) {
				margin-left: auto;
				margin-right: 0px;
			}
		}
	}
	.missao__card{
		@include media-breakpoint-down (sm) {
			margin-bottom: 20px;
		}

	}
}
.missao__icone{
	margin-bottom: 5px;
}
.missao__titulo{
	font-family: poppinsbold;
	font-size: 15px;
	line-height: 18px;
	color: #2d286b;
	margin-bottom: 10px;
}
.missao__desc{
	font-family: poppinslight;
	font-size: 14px;
	line-height: 16px;
	text-align: justify;
}

.clientes__grid{
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 10px;
	row-gap: 10px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(4, 1fr);
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(3, 1fr);
	}

	.cliente__card{
		display: block;
		border: 1px solid #d2d2d2;
		border-radius: 5px;
		overflow: hidden;

		img{
			border-radius: 5px;
		}
	}
}
