// CARRINHO PEDIDOS

.carrinho__header{
	display: flex;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	background-color: #2d286b;
	border-radius: 5px;
	overflow: hidden;

	& > *{
		font-family: 'poppinssemibold' !important;
		border-radius: 0px !important;
	}
}
.carrinho__produto{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
}
.carrinho__quantidade{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
}
.carrinho__valor__uni{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'poppinslight';
}
.carrinho__valor__total{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'poppinssemibold'
}



.pedido__detalhe__listagem{
	margin-bottom: 10px;
	padding-bottom: 0px;
	border-radius: 5px;
	overflow: hidden;
	border-bottom: 1px solid #d1d1d1;
	border-left: 1px solid #d1d1d1;
	border-right: 1px solid #d1d1d1;

	@include media-breakpoint-down (md) {
		border-left: none;
		border-right: none;
	}


	.carrinho__item{
		background-color: #eeeeee;
        color: #000;
		border: none;
		margin-bottom: 0px;
		border-bottom: 1px solid #d1d1d1;
		margin-left: 30px;
		margin-right: 30px;

		&:last-child{
			border-bottom: none;
		}

		.carrinho__produto,
		.carrinho__quantidade,
        .carrinho__valor__uni,
        .carrinho__valor__total{
            border: none;
            color: #000;
            font-family: 'poppinsregular';

			.segura__unidade__valor{
				font-family: 'poppinssemibold';
				color: #000;
			}

			.carrinho__produto__card{
				align-items: center;
			}

            .carrinho__produto__desc{
                font-family: 'poppinsregular';
                font-size: 14px;
                color: #000;
				padding-top: 0px;
            }
			.segura__quantidade{
				font-size: 14px;
				line-height: 14px;
				font-family: 'poppinsregular';
			}
            .segura__unidade__valor{
                .carrinho__valor__promocao,
                .carrinho__valor__unidade,
				.numero__valor__unidade,
				.segura__unidade__valor{
					font-family: 'poppinsregular';
					font-size: 20px;
					line-height: 24px;
                }
            }
			.segura__unidade__valor{
				color: #000;
			}
		}
	}
}




.pedido__item{
	display: flex;
}
.pedidos__listagem__detalhe{
	max-width: 920px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.interna__pedidos{
	max-width: 920px;
	width: 100%;
	margin-right: auto;
    margin-left: auto;

    .interna__titulo{
        margin-bottom: 15px;
    }
}
.pedido__id__item,
.pedido__valor__item,
.pedido__status__item__2,
.pedido__btn__item{
	padding: 18px;

	@include media-breakpoint-down (md) {
		padding: 8px !important;
	}
}
.pedido__id__item{
	background-color: #dbdbdb;
	color: #000;
	font-family: 'poppinssemibold';
	font-size: 14px;
	line-height: 17px;
	transition: all 0.3s linear;
	padding-left: 30px ;
}
.pedido__item{
	display: flex;
	margin-bottom: 10px;
	border-radius: 5px;
	overflow: hidden;
	border: 1px solid #d1d1d1;
	background-color: #eeeeee;

	&:hover{
		.pedido__id__item{
			background-color: #e78933;
			color: #fff;
		}
	}
}
.titulo__interna{
    &.interna__pedido__detalhe{
        margin-bottom: 20px;
        color: #000;
    }
}
.pedido__realizado{
	display: flex;
	align-items: center;
}
.pedido__numero{
	margin-bottom: 10px;
}
.pedido__data{
	margin-left: 5px;
}
.pedido__valor,
.pedido__status{
	color: #000;
	font-family: poppinsregular;
}
.pedido__status__item__2,
.pedido__valor__item,
.pedido__btn__item{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eeeeee;
	font-family: 'poppinsregular';
	font-size: 14px;
	line-height: 17px;
	color: #000;
	text-align: left;
	width: 100%;
}
.pedido__btn{
	display: flex;
	width: 145px;
    height: 50px;
	color: #fff;
	background-color: #2d286b;
	border: none;
	font-family: 'poppinssemibold';
    font-size: 14px;
    line-height: 16px;
	align-items: center;
	justify-content: center;
    transition: all 0.3s linear;
    padding-top: 1px;
	border-radius: 5px;
	position: relative;

	@include media-breakpoint-down (sm) {
		height: 36px;
		font-size: 12px;
		line-height: 14px;
		border-radius: 18px;
	}

	span{
		position: relative;
		z-index: 200;
	}


	&:hover{
		text-decoration: none;
		background-color: #e78933;
		color: #fff;
	}
}
.pedidos__listagem{
    max-width: 930px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

	.carrinho__item{
		.carrinho__produto{
			border: none;
		}
		.carrinho__quantidade{
			border: none;
		}
		.carrinho__valor__uni{
			border: none;
		}
		.carrinho__valor__total{
			border: none;
		}
	}
}
.pedido__detalhe__header{
	display: flex;
	justify-content: space-between;
	margin-bottom: 26px;
	align-items: center;
}
.pedido__detalhes__numero{
	font-family: 'poppinssemibold';
	font-size: 30px;
	line-height: 34px;
	color: #000;
}
.pedidos__detalhes__segura__btn{
	display: flex;
	justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    .btn__interna{
        min-width: 240px;
    }

	.voltar__btn{
        margin: 5px 10px 5px 0px;
        min-width: 140px;
    }
}
.pedido__detalhe__dados{
	border: none;
	padding: 25px 20px;
	display: flex;
	font-family: 'poppinsregular';
	font-size: 14px;
	line-height: 18px;
	justify-content: space-between;
	color: #000;
	margin-bottom: 25px;
	background-color: #ededed;
	border: 1px solid #d1d1d1;
	border-radius: 5px;
	overflow: hidden;
}
.pedido__detalhe__status{
	display: flex;
	justify-content: space-between;
	margin-bottom: 70px;
	align-items: center;
}
.titulo__pedido__status{
	font-family: 'poppinssemibold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
}
.pedido__detalhes__titulo{
    font-family: 'poppinssemibold';
    font-size: 30px;
    line-height: 34px;
    color: #000;
    margin-bottom: 40px;
}
.pedido__status__estagio{
	display: flex;
	justify-content: flex-end;
}
.pedido__status__item{
	display: flex;
	padding: 7px 5px 8px 12px;
	background-color: #2d286b;
	color: #fff;
	font-family: 'poppinssemibold';
	font-size: 13px;
	line-height: 13px;
	position: relative;
	text-align: center;
	justify-content: center;
	align-items: center;

	.caminhao__icone{
		background-color: #fff;
		transition: all 0.3s linear;
	}

	&.pedido__status__item--active{
		background: #e78933;
		font-family: 'poppinssemibold';

		&:after{
			background: #e78933;
		}
	}

	&:first-child{
		border-radius: 5px 0px 0px 5px;
		&:before{
			display: none;
		}
	}
	&:last-child{
		border-radius: 0px 5px 5px 0px;

		span{
			margin-right: 10px;
		}
		&:after{
			display: none;
		}
	}
	&:before{
		content: '';
		position: absolute;
		left: -1px;
		top: 0px;
		width: 12px;
		height: 100%;
		background-color: #fff;
		clip-path: polygon(100% 50%, 0 0, 0 100%);
	}
	&:after{
		content: '';
		position: absolute;
		width: 12px;
		height: 100%;
		top: 0px;
		right: -11px;
		background-color: #2d286b;
		clip-path: polygon(100% 50%, 0 0, 0 100%);
	}
}

.pedido__pagamento__titulo{
	font-family: 'poppinssemibold';
	font-size: 30px;
	line-height: 34px;
	color: #000;
	margin-bottom: 30px;
}
.pedido__pagamento__conteudo{
	display: flex;
	font-family: 'poppinsregular';
	font-size: 14px;
	line-height: 18px;
	color: #000;
	background-color: #ededed;
	border: 1px solid #d1d1d1;
	border-radius: 5px;
	overflow: hidden;
}
.pedido__detalhe__pagamento{
	padding-top: 30px;
}
.pedido__segura__pagamento__forma{
	padding: 35px 13px 35px 40px;
	background-color: #ededed;
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	width: 25%;
	font-family: 'poppinssemibold';
	color: #000;
}
.row__pesquisa{
	.produto__card{
		margin-bottom: 20px;
	}
}
.pedido__segura__pagamento{
	display: flex;
	justify-content: center;
	position: relative;
	width: 75%;
	background-color: #eeeeee;
}
.pedido__segura__pagamento__valor,
.pedido__segura__frete__valor{
	width: 30%;
	padding: 35px 13px;
	background-color: #eeeeee;
	font-family: 'poppinsregular';
}
.pedido__segura__total__valor{
	width: 100%;
	max-width: 42%;
	background-color: #dbdbdb;
	padding: 35px 25px 35px 35px;
	color: #000;
	font-family: 'poppinssemibold';
}
.pedido__total__valor,
.pedido__frete__valor,
.pedido__pagamento__valor,
.pedido__pagamento__forma{
	font-size: 14px;
	line-height: 18px;
}
.row__pesquisa{
	margin-bottom: 30px;
}
.pedido__pagamento__btn{
	padding-top: 25px;
	display: flex;
	justify-content: flex-end;
}
.row__categ__produtos{
	.col-xl-4{
		margin-bottom: 20px;
	}
}
.caminhao__icone{
    width: 23px;
    height: 17px;
    mask-image: url('../images/caminhao__icone.png');
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: center;
	background-color: currentColor;
    min-width: 23px;
    margin-right: 5px;
}
.pedido__detalhe__data__item{
	span{
		font-family: poppinssemibold;
	}
}
@include media-breakpoint-up (lg) {
	// CARRINHO
	.pedido__detalhe__listagem{
		.carrinho__produto{
			width: 38%;
		}
		.carrinho__quantidade{
			width: 23%
		}
		.carrinho__valor__uni{
			width: 22%;
		}
		.carrinho__valor__total{
			width: 17%;
		}
	}
	// FIM DO CARRINHO
	.pedido__id__item{
		width: 100%;
		max-width: 35%;
	}
	.pedido__valor__item{
		width: 100%;
		max-width: 25%;
	}
	.pedido__status__item__2{
		width: 100%;
		max-width: 30%;
	}
	.pedido__btn__item{
		width: 100%;
		max-width: 20%;
	}
	.pedido__detalhes__numero{
		width: 100%;
		max-width: 60%;
	}
	.pedido__detalhes__segura__btn{
		width: 100%;
		max-width: 40%;
	}
	.pedido__detalhe__data__item{
		width: 100%;
		max-width: 25%;
	}
	.pedido__detalhe__endereco__item{
		width: 100%;
		max-width: 75%;
	}
	.pedido__status__item{
		width: 145px;
		margin: 0px 6px;

		&:last-child{
			width: 200px;
			margin: 0px 0px 0px 6px;
		}
	}
	.pedido__detalhe__listagem{
		background-color: #eeeeee;
		margin-bottom: 50px;

		.carrinho__quantidade{
			width: 17%;
		}
		.carrinho__valor__total{
			width: 23%;
		}
	}
}
@include media-breakpoint-down (md) {
	.pedido__item{
		flex-direction: column;
		text-align: center;
	}
	.pedido__realizado{
		justify-content: center;
	}
	.segura__valor__item,
	.segura__status__item{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.pedido__detalhe__header{
		flex-direction: column;
		align-items: center;

		.pedido__detalhes__numero{
			margin-bottom: 10px;
		}
	}
	.pedido__detalhe__status{
		flex-direction: column;
	}
	.titulo__pedido__status{
		margin-bottom: 10px;
	}
	.pedido__status__estagio{
		flex-direction: column;
	}
	.pedido__status__item{
		margin: 10px 0px;
		padding: 40px 15px 15px 15px;

		&:first-child{
			border-radius: 0px !important;
			padding-top: 25px;
		}

		&:after{
			width: 100%;
			height: 20px;
			background-color: #2d286b;
			clip-path: polygon(50% 100%, 100% 0, 0 0);
			left: 0px;
			top: calc(100% - 1px);
		}
		&:before{
			top: -1px;
			left: 0px;
			width: 100%;
			height: 20px;
			background-color: #fff;
			clip-path: polygon(50% 100%, 100% 0, 0 0);
		}
	}
	.pedido__pagamento__conteudo{
		flex-direction: column;

		.pedido__segura__pagamento__forma{
			margin-right: 0px;
			margin-bottom: 20px;

		}
	}
	.pedido__pagamento__btn{
		justify-content: center;
	}
	.pedido__segura__pagamento{
		justify-content: center;
		width: 100%;
	}
	.pedido__segura__pagamento__forma{
		width: 100%;
		align-items: center;
	}
	.pedido__detalhe__dados{
		flex-direction: column;
		text-align: center;
		align-items: center;
	}
	.pedido__detalhe__data__item{
		margin-bottom: 10px;
	}
}
@include media-breakpoint-down (xs) {
	.pedido__segura__pagamento{
		flex-direction: column;
		padding: 0px;
	}
	.pedido__segura__pagamento__valor,
	.pedido__segura__frete__valor,
	.pedido__segura__total__valor{
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
	.pedidos__detalhes__segura__btn{
		flex-direction: column;
		align-items: center;

		.pedido__detalhes__btn{
			margin: 10px 0px;
		}
	}
}
.titulo__interna__alt{
	text-align: center;
	padding-top: 50px;
	margin-bottom: 60px;
	border-top: 5px solid #f8f8f8;
	color: #c2322e;
	font-family: poppinssemibold;
	font-size: 30px;
	line-height: 34px;
}
