.banner__carousel{
	overflow: hidden;
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators{
		bottom: 20px;
		@include media-breakpoint-down (sm) {
			bottom: 0px;
		}
		li{
			width: 16px;
			height: 12px;
			background-color: transparent;
			position: relative;
			border: none;
			border-radius: 6px;
			margin: 0px 6px;
			opacity: 1;
			text-indent: 0px;
			transition: all 0.3s linear;

			span{
				position: absolute;
				top: -18px;
				left:50%;
				transform: translateX(-50%);
				color: #fff;
				font-size: 12px;
				line-height: 14px;

				@include media-breakpoint-down (sm) {
					display: none;
				}
			}

			&.active{
				margin-right: 45px;
				background-color: #fff;
				@include media-breakpoint-down (sm) {
					margin-right: 6px;

					&:before{
						display: none;
					}
				}
				&:before{
					width: 30px;
				}
			}

			&:after{
				width: 16px;
				height: 12px;
				top: 50%;
				left: 50%;
				content: '';
				position: absolute;
				border: 1px solid #fff;
				border-radius: 6px;
				transform: translate(-50%, -50%);
				opacity: 1;
				transition: all 0.3s linear;
			}

			&:before{
				width: 0px;
				height: 2px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: calc(100% + 12px);
				content: '';
				background-color: #fff;
				transition: all 0.3s linear;
			}
		}
	}
}
.produto__card{
	max-width: 255px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #ededed;
	border-radius: 5px;
	overflow: hidden;
	// &:hover{
	// 	// @include media-breakpoint-up (lg){
	// 	// 	.produto__card__btns{
	// 	// 		height: 55px;
	// 	// 		max-height: 55px;
	// 	// 		overflow: hidden;
	// 	// 		position: relative;
	// 	// 		padding-bottom: 25px;

	// 	// 		& > *{
	// 	// 			opacity: 1;
	// 	// 		}
	// 	// 	}
	// 	// }
	// }
	a{
		&:hover{
			text-decoration: none;
		}
	}

	.produto__valor__promocao{
		font-family: poppinsmedium;
		color: #999999;
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 2px;
		text-decoration: line-through;
	}
	.produto__valor__total{
		font-family: poppinssemibold;
		color: #000;
		font-size: 23px;
		line-height: 25px;
	}
	.produto__card__valor{
		margin-bottom: 15px;
	}
	.produto__card__titulo{
		font-family: poppinsmedium;
		font-size: 14px;
		line-height: 18px;
		color: #000;
		margin-bottom: 15px;
	}
	.produto__valor__boleto{
		font-family: poppinslight;
	}
	.produto__card__juros{
		font-size: 13px;
		line-height: 15px;
		font-family: poppinslight;

		span{
			font-family: poppinssemibold;
		}
	}
	.produto__card__conteudo{
		padding: 20px 20px 20px 20px;
		display: block;
	}
	.produto__card__btns{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 25px;
		column-gap: 10px;
		transition: all 0.3s linear;

		& > *{
			width: 100%;
			max-width: 110px;
			height:30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border: none;
			background-color: #2d286b;
			color: #fff;
			column-gap: 5px;
			font-family: poppinssemibold;
			font-size: 13px;
			line-height: 15px;
			border-radius: 5px;
			transition: all 0.3s linear;


			&:hover{
				background-color: #e78933;
				color: #fff;
				text-decoration: none;
			}
		}
	}
	.produto__card__imagem{
		position: relative;
		display: block;

		img{
			position: relative;
			z-index: 80;
		}
	}
	.produto__card__tags{
		position: absolute;
		left: 20px;
		top: 20px;
		display: flex;
		flex-direction: column;
		width: 80px;
		row-gap: 5px;
		z-index: 100;

		.produto__card__tag{
			background-color: #00a551;
			color: #fff;
			width: 100%;
			max-width: 120px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items:center;
			column-gap: 3px;
			text-align: center;
			border-radius: 5px;
			color: #fff;
			font-family: poppinssemibold;
			font-size: 13px;
			line-height: 15px;
		}
	}
}
.quem__home{
	padding-top: 90px;
	padding-bottom: 45px;

	@include media-breakpoint-down (md) {
		padding: 45px 0px 25px;

		.col-lg-6{
			&:nth-child(1) {
				order: 2;
			}
			&:nth-child(2) {
				order: 1;
			}
		}
	}

	.row{
		align-items: center;
	}
}
.quem__img__home{
	text-align: center;
}
.titulo__quem__home,
.titulo__quem__int{
	font-family: poppinsbold;
	font-size: 28px;
	line-height: 32px;
	color: #e78933;
	margin-bottom: 20px;
}
.quem__desc{
	text-align: justify;
}
.tab__missao{
	display: flex;
	column-gap: 12px;



	.nav-tabs{
		min-width: 125px;
		max-width: 125px;
		position: relative;
		z-index: 15;
		border: none;
		padding: 0px;
		margin: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		row-gap: 10px;

		.nav-item{
			margin: 0px;
			border: none;
			padding: 0px;
			width: 100%;
		}

		.nav-link{
			border: none;
			background-color: transparent;
			position: relative;
			z-index: 10;
			color: #fff;
			font-family: poppinssemibold;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.5px;
			padding: 10px 8px 10px 14px;
			display: block;
			margin: 0px;

			&:after{
				width: 100%;
				height: 100%;
				top: 0px;
				background-color: #2d286b;
				content: '';
				position: absolute;
				z-index: -1;
				left: 0px;
				border-radius: 5px 0px 0px 5px;
				transition: all 0.3s linear;
			}

			&.active{
				&:after{
					background-color: #e78933;
					width: calc(100% + 36px);
					border-radius: 5px;
				}
			}
		}
	}
	.tab-content{
		border-radius: 0px 5px 5px 5px;
		background-color: #2d286b;
		color: #fff;
		padding: 25px 25px 25px 40px;
	}
	@include media-breakpoint-down (md) {
		flex-direction: column;
		margin-bottom: 30px;
		.nav-tabs{
			flex-direction: row;
			flex-wrap: wrap;
			max-width: unset;
			width: 100%;
			margin-bottom: 10px;
			justify-content: center;
			display: flex;
			column-gap: 5px;

			.nav-item{
				max-width: 125px;

				.nav-link{
					text-align: center;
					&:after{
						border-radius: 5px;
					}
					&.active{
						&:after{
							width: 100%;
						}
					}
				}

			}
		}
	}
}
.certi{
	padding: 45px 0px 45px 0px;

	@include media-breakpoint-down (md) {
		padding: 25px 0px 25px 0px;
	}

	.cert__grid{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 10px;
		row-gap: 10px;

		@include media-breakpoint-down (sm) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 10px;
			row-gap: 10px;
		}
	}
}
.certi{
	.container{
		@include media-breakpoint-up (lg) {
			display: grid;
			grid-template-columns: 230px 1fr;
			column-gap: 25px;
			align-items: center;
		}
	}
}
.cert__titulo{
	font-family: poppinsbold;
	font-size: 28px;
	line-height: 32px;
	color: #e78933;

	@include media-breakpoint-down (md) {
		text-align: center;
		margin-bottom: 35px;
	}
}
.video__home{
	padding: 45px 0px 90px;
	@include media-breakpoint-down (md) {
		padding: 25px 0px 45px 0px;
	}
	.row{
		align-items: center;
	}
}

.atitude__img{
	text-align: center;
	margin-bottom: 15px;
}

.atitude__desc{
	text-align: justify;

	@include media-breakpoint-down (md) {
		margin-bottom: 20px;
	}
}

.btn__video{
	position: relative;
	background-color: transparent;
	border: none;
	padding: 0px;
	display: flex;
	align-items: center;

	.btn__video__helper{
		margin-right: -65px;
		min-width: 130px;
		width: 130px;
		height: 130px;
		background-color: #e78933;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		z-index: 200;
		position: relative;
		color: #fff;

		&:after{
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			content: '';
			top: 0px;
			left: 0px;
			animation: pulsar 2s linear infinite;
			background-color: #e78933;
			border-radius: 50%;
		}
	}

	.btn__video__img {
		img{
			border-radius: 50%;
		}
	}
}
@keyframes pulsar{
    0%{
        opacity: 0.7;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(2);
    }
}

.quem__img__int{
	text-align: center;
}
.quem__row{
	row-gap: 25px;
	margin-bottom: 80px;
	align-items: center;

	@include media-breakpoint-down (md) {
		margin-bottom: 40px;
	}
}
.missao__int{
	margin-bottom: 45px;

	@include media-breakpoint-down (md) {
		margin-bottom: 25px;
	}
}

.btn__video__int{
	position: relative;
	background-color: transparent;
	border: none;
	padding: 0px;
	display: flex;
	align-items: center;

	.btn__video__helper{
		min-width: 65px;
		width: 65px;
		height: 65px;
		background-color: #e78933;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		z-index: 200;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		color: #fff;

		&:after{
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			content: '';
			top: 0px;
			left: 0px;
			animation: pulsar 2s linear infinite;
			background-color: #e78933;
			border-radius: 50%;
		}
	}

	.btn__video__img {
		img{
			border-radius: 5px;
		}
	}
}
